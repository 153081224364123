.body{
  background-color: black;
  font-family: sans-serif;
}
.header {
    background-color: white;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
}

.headerbutton {
  height: 50px;
  width: 100px;
  border: 0px;
  border-radius: 10px;
  background-color: burlywood;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.firstsectionone {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
.firstsection {
  margin-top: 30px;
  /* margin-left: 50px; */
  width: 50%;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}
.deletebutton {
    height: 50px;
    width: 100px;
    border: 0px;
    border-radius: 10px;
    background-color: red;
    font-size: 20px;
    font-weight: 600;
  }
.input{
  border: 2px solid black;
  border-radius: 6px;
  width: 40%;
  padding: 10px;
  font-weight: bold;
}
.input::placeholder {
  color: rgb(128, 128, 128);
  font-weight: bold;
}
.textarea{
  border: 2px solid black;
  border-radius: 6px;
  width: 50%;
  padding: 10px;
  font-weight: bold;
  height: 140px;
}
.submitbutton{
  height: 50px;
    width: 100px;
    border-radius: 10px;
    background-color: white;
    font-size: 30px;
    font-weight: bolder
}
.messageText{
  color: red;
}


@media screen and (max-width: 600px) {
  .headerbutton {
    height: 50px;
    width: 80px;
  }

  .firstsection {
    margin-top: 30px;
    width: 80%;
    background-color: white;
    padding: 10px;
    border-radius: 12px;
  }
  .input{
    border: 2px solid black;
    border-radius: 6px;
    width: 50%;
    padding: 10px;
    font-weight: bold;
  }
  .textarea{
    border: 2px solid black;
    border-radius: 6px;
    width: 85%;
    padding: 10px;
    font-weight: bold;
    height: 160px;
  }
  }