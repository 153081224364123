.footer {
  background-color: black;
  color: #0275d8;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
}
.footerDisplay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 40px;
}
.p {
  font-size: 200%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #0275d8;
}
.ul{
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
}
.circledradius1{
  border-radius: 1000px;
  width: 80px;
  height: 80px;
  border: 2px solid #0275d8;
}
.circledradius{
    border-radius: 1000px;
    width: 80px;
    height: 80px;
    border: 2px solid #0275d8;
}
.circledradius:hover{
    border: 2px solid white;
}
.locationtext{
    color: white;
}

@media screen and (max-width: 840px) {
  .footerDisplay {
    display: flex;
    flex-direction: column;
  }
  .p {
    font-size: 170%;
  }
}
