.famImage{
    width: 46%;
    height: 520px;
    border-radius: 15px;
}
.famImage1{
    width: 48%;
    height: 700px;
    border-radius: 15px;
}


@media screen and (max-width: 600px) {
    .famImage{
        width: 80%;
        height: 390px;
        border-radius: 15px;
    }
    .famImage1{
        width: 80%;
        height: 395px;
        border-radius: 15px;
    }
}