.breastImg{
    width: 70%;
    height: 600px;
}
.breast2Img{
    width: 80%;
    height: 1000px;
}
.breast3Img{
    width: 80%;
    height: 700px;
}

@media screen and (max-width: 700px) {
    .breastImg{
        width: 90%;
        height: 400px;
        border-radius: 10px;
    }
    .breast2Img{
        width: 100%;
        height: 600px;
        border-radius: 10px;
    }
    .breast3Img{
        width: 100%;
        height: 500px;
        border-radius: 10px;
    }
}