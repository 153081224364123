.input{
    border: 2px solid black;
    border-radius: 6px;
    width: 400px;
    padding: 10px;
    font-weight: bold;
}
.input::placeholder {
    color: rgb(128, 128, 128);
    font-weight: bolder;
  }
  .messageText{
    color: red;
    font-weight: bolder;
  }


@media screen and (max-width: 800px) {
    .input{
        width: 300px;
        padding: 8px;
    }
}