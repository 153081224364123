.packageSurgeryText {
  font-size: 113%;
  font-weight: 300;
  font-family: sans-serif;
  text-align: start;
}

@media screen and (max-width: 500px) {
  .specimg {
    height: 500px;
  }
  .packageSurgeryText {
    font-size: 100%;
    width: 97%;
  }
}
